import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import { AUTH_LOGIN, AUTH_LOGOUT } from './actionTypes';
import { TOKEN_KEY, USER_UID_KEY, TIMEZONE_KEY, IS_LOGGED_IN_KEY } from '../utils/cookieConst';

const initialState = {
  auth: {
    isLoggedIn: localStorage.getItem(IS_LOGGED_IN_KEY) === 'true',
    userUid: localStorage.getItem(USER_UID_KEY) || null,
    jwt: localStorage.getItem(TOKEN_KEY) || null,
  },
};

const syncWithLocalStorage = store => next => action => {
  const result = next(action);

  // after state has been updated by the action, save to local storage
  if (action.type === AUTH_LOGOUT) {
    localStorage.removeItem(IS_LOGGED_IN_KEY);
    localStorage.removeItem(USER_UID_KEY);
    localStorage.removeItem(TOKEN_KEY);

  } else if (action.type === AUTH_LOGIN) {
    localStorage.setItem(IS_LOGGED_IN_KEY, true);
    localStorage.setItem(USER_UID_KEY, action.userUid);
    localStorage.setItem(TOKEN_KEY, action.jwt );
  }
  
  return result;
};

const middleware = [thunk, syncWithLocalStorage];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);


export default store;

import React, { useEffect, useRef } from "react";
import D3Funnel from "d3-funnel";

const CustomerFunnel = ({ totalUserCount, usersAwaitingEmailConfirmation, usersAwaitingPersonalInformation, usersAwaitingToSubmitId }) => {
    const funnelRef = useRef(null);

    useEffect(() => {
        const data = [
            { label: "Users at: No Email Confirmation", value: usersAwaitingEmailConfirmation },
            { label: "Users at: No Personal Information Submitted", value: usersAwaitingPersonalInformation },
            { label: "Users at: No ID submission", value: usersAwaitingToSubmitId },
        ];

        const totalUsers = totalUserCount || 1; // Use 1 to prevent division by zero

        const formattedData = data.map(item => ({
            label: `${item.label}: ${item.value} (${totalUsers > 0 ? ((item.value / totalUsers) * 100).toFixed(2) : 0}%)`,
            value: item.value
        }));

        const options = {
            block: {
                dynamicHeight: true,
                highlight: true,
                minHeight: 20, // Set minimum height to avoid very thin blocks
            },
            chart: {
                width: funnelRef.current.offsetWidth,
                height: 400,
                bottomPinch: 1,
                orientation: "horizontal",
            },
            label: {
                format: "{l}",
                fontSize: "14px", // Adjust font size for better readability
            },
            tooltip: {
                enabled: true
            },
        };

        const funnel = new D3Funnel(funnelRef.current);
        funnel.draw(formattedData, options);
    }, [totalUserCount, usersAwaitingEmailConfirmation, usersAwaitingPersonalInformation, usersAwaitingToSubmitId]);

    return <div ref={funnelRef} style={{ width: "100%", height: "400px" }} />;
};

export default CustomerFunnel;

import React from "react";
import { NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const NotificationItem = ({ notification, notificationRead, link }) => {
  const { message, sentDate, sentTime } = notification;

  const className = notificationRead
    ? "notification-item-read"
    : "notification-item-unread";

  const formatDateOrTime = (sentDate, sentTime) => {
    const currentDate = new Date();
    const notificationDate = new Date(`${sentDate}T${sentTime}`);

    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };

    if (currentDate.toDateString() === notificationDate.toDateString()) {
      const todayTime = notificationDate.toLocaleTimeString([], timeOptions);
      return `Today at ${todayTime}`;
    } else {
      const dateOptions = { year: "numeric", month: "short", day: "numeric" };
      return notificationDate.toLocaleDateString([], dateOptions);
    }
  };

  const dateStyle = {
    fontSize: "0.75rem",
    color: "#6c757d",
    display: "block",
    marginTop: "4px",
  };

  const formattedDateOrTime = formatDateOrTime(sentDate, sentTime);

  const content = (
    <>
      {message}
      <span style={dateStyle}>{formattedDateOrTime}</span>
    </>
  );

  return link ? (
    <LinkContainer to={link}>
      <NavDropdown.Item className={className}>{content}</NavDropdown.Item>
    </LinkContainer>
  ) : (
    <NavDropdown.Item className={className}>{content}</NavDropdown.Item>
  );
};

export default NotificationItem;

import React from 'react';
import { Card, Typography, Box, Grid, Tooltip } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { styled } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';

const IconBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
}));

const StatsBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'scale(1.05)',
    },
}));

const AverageCallsStats = ({ weekData, monthData, lastTwoDays }) => {
    // Ensure that values are numbers
    const lastTwoDaysFormatted = typeof lastTwoDays === 'number' ? lastTwoDays.toFixed(1) : 'N/A';
    const weekDataFormatted = typeof weekData === 'number' ? weekData.toFixed(1) : 'N/A';
    const monthDataFormatted = typeof monthData === 'number' ? monthData.toFixed(1) : 'N/A';

    return (
        <Card sx={{ p: 2, mb: 2, boxShadow: 3 }}>
            <Typography variant="h5" component="h3" gutterBottom sx={{ textAlign: 'center' }}>
                Avg Calls/User
            </Typography>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} sm={4}>
                    <Tooltip title="Calls in the last 2 days" TransitionComponent={Zoom}>
                        <StatsBox>
                            <IconBox>
                                <AccessTimeIcon sx={{ color: '#ffa726' }}/>
                            </IconBox>
                            <Typography>Last 2 Days: {lastTwoDaysFormatted}</Typography>
                        </StatsBox>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Tooltip title="Calls in the last week" TransitionComponent={Zoom}>
                        <StatsBox>
                            <IconBox>
                                <DateRangeIcon sx={{ color: '#1976d2' }}/>
                            </IconBox>
                            <Typography>Last Week: {weekDataFormatted}</Typography>
                        </StatsBox>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Tooltip title="Calls in the last month" TransitionComponent={Zoom}>
                        <StatsBox>
                            <IconBox>
                                <TrendingUpIcon sx={{ color: '#2e7d32' }}/>
                            </IconBox>
                            <Typography>Last Month: {monthDataFormatted}</Typography>
                        </StatsBox>
                    </Tooltip>
                </Grid>
            </Grid>
        </Card>
    );
};

export default AverageCallsStats;

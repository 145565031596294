import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Box, Typography} from '@mui/material';
import {getExternalApiCallsData} from "../../../api";
import AverageCallsStats from "./Components/AverageCallsStats";

const ApiCallsPricingStats = () => {
    const [totalAggregatedExternalApiCallsStats, setTotalAggregatedExternalApiCallsStats] = useState([]);
    const [totalAggregatedExternalApiCallsPerMethod, setTotalAggregatedExternalApiCallsPerMethod] = useState([]);
    const [averageCallsPerUserPerMethodLastWeek, setAverageCallsPerUserPerMethodLastWeek] = useState([]);
    const [averageCallsPerUserPerMethodLastMonth, setAverageCallsPerUserPerMethodLastMonth] = useState([]);
    const [averageCallsPerUserPerMethodLastTwoDays, setAverageCallsPerUserPerMethodLastTwoDays] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const externalApiCallData = await getExternalApiCallsData();

                setTotalAggregatedExternalApiCallsStats(externalApiCallData.totalAggregatedExternalApiCallsStats || []);
                setTotalAggregatedExternalApiCallsPerMethod(externalApiCallData.totalAggregatedExternalApiCallsPerMethod || []);
                setAverageCallsPerUserPerMethodLastWeek(externalApiCallData.averageCallsPerUserPerMethodLastWeek || []);
                setAverageCallsPerUserPerMethodLastMonth(externalApiCallData.averageCallsPerUserPerMethodLastMonth || []);
                setAverageCallsPerUserPerMethodLastTwoDays(externalApiCallData.averageCallsPerUserPerMethodLastTwoDays || []);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (error) {
        return <Typography color="error">An error occurred: {error.message}</Typography>;
    }

    return (
        <>
            <Box>
                <AverageCallsStats
                    weekData={averageCallsPerUserPerMethodLastWeek}
                    monthData={averageCallsPerUserPerMethodLastMonth}
                    lastTwoDays={averageCallsPerUserPerMethodLastTwoDays}
                />

                <Paper elevation={3} style={{margin: '20px', overflowX: 'auto'}}>
                    <Typography variant="h4" style={{textAlign: 'center', margin: '20px 0'}}>Call Stats</Typography>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Service Name</TableCell>
                                <TableCell>Method Name</TableCell>
                                <TableCell>User Client ID</TableCell>
                                <TableCell>Call Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {totalAggregatedExternalApiCallsStats.length > 0 ? (
                                totalAggregatedExternalApiCallsStats.map((stat, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {stat.serviceName}
                                        </TableCell>
                                        <TableCell>{stat.methodName}</TableCell>
                                        <TableCell>{stat.userClientId}</TableCell>
                                        <TableCell>{stat.callCount}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        No data available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Paper>
            </Box>

            <Box>
                <Paper elevation={3} style={{margin: '20px', overflowX: 'auto'}}>
                    <Typography variant="h4" style={{textAlign: 'center', margin: '20px 0'}}>Pricing</Typography>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Service Name</TableCell>
                                <TableCell>Method Name</TableCell>
                                <TableCell>Call Count</TableCell>
                                <TableCell>Prepay Credit - Tier 1</TableCell>
                                <TableCell>Prepay Credit - Tier 2</TableCell>
                                <TableCell>Prepay Credit - Tier 3</TableCell>
                                <TableCell>Monthly Subscription - S</TableCell>
                                <TableCell>Monthly Subscription - M</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {totalAggregatedExternalApiCallsPerMethod.length > 0 ? (
                                totalAggregatedExternalApiCallsPerMethod
                                    .filter(stat => stat.serviceName === "UKVD")
                                    .map((stat, index) => {
                                        const calculateCost = (methodName, callCount) => {
                                            if (typeof callCount !== 'number') return "0.00";

                                            switch (methodName) {
                                                case 'getVehicleImage':
                                                    return callCount * 0.02;
                                                case 'getVehicleInformation':
                                                    return callCount * 0.15;
                                                default:
                                                    return 0;
                                            }
                                        };

                                        const tier1Cost = calculateCost(stat.methodName, stat.callCount);


                                        console.log('tier1Cost', tier1Cost);
                                        const tier2Cost = calculateCost(stat.methodName, stat.callCount * 0.8); // Example modifier for tier 2
                                        const tier3Cost = calculateCost(stat.methodName, stat.callCount * 0.5); // Example modifier for tier 3
                                        const subscriptionSCost = calculateCost(stat.methodName, stat.callCount * 0.5); // Example modifier for subscription S
                                        const subscriptionMCost = calculateCost(stat.methodName, stat.callCount * 0.4); // Example modifier for subscription M

                                        return (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {stat.serviceName}
                                                </TableCell>
                                                <TableCell>{stat.methodName}</TableCell>
                                                <TableCell>{stat.callCount}</TableCell>
                                                <TableCell>£{tier1Cost}</TableCell>
                                                <TableCell>£{tier2Cost.toFixed(2)}</TableCell>
                                                <TableCell>£{tier3Cost.toFixed(2)}</TableCell>
                                                <TableCell>£{subscriptionSCost.toFixed(2)}</TableCell>
                                                <TableCell>£{subscriptionMCost.toFixed(2)}</TableCell>
                                            </TableRow>
                                        );
                                    })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        No data available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </Paper>
            </Box>
        </>
    );
};

export default ApiCallsPricingStats;

import React, { useState, useEffect } from "react";
import { getUser, incrementUserTokens, deleteUser } from "../../api";
import {
    Box,
    Typography,
    Button,
    CircularProgress,
    Grid,
    Card,
    CardContent,
    CardActions,
    Divider,
    List,
    ListItem,
    ListItemText,
    Alert,
    Stack,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";

const UserDetailPage = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [incrementQuantity, setIncrementQuantity] = useState(1);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const fetchUserData = async () => {
        setIsLoading(true);
        try {
            const data = await getUser(userId);
            console.log("Fetched User:", JSON.stringify(data, null, 2));
            setUser(data);
        } catch (err) {
            setError(err.message || "An error occurred while fetching user details");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [userId]);

    const handleIncrementTokens = async () => {
        setIsUpdating(true);
        try {
            await incrementUserTokens(userId, incrementQuantity);
            await fetchUserData();
        } catch (err) {
            setError(err.message || "An error occurred while updating tokens");
        } finally {
            setIsUpdating(false);
        }
    };

    const handleDeleteUser = async () => {
        setIsDeleting(true);
        try {
            await deleteUser(userId);
            navigate(-1); // Redirect to the user list after deletion
        } catch (err) {
            setError(err.message || "An error occurred while deleting the user");
        } finally {
            setIsDeleting(false);
        }
    };

    const openDeleteDialog = () => setIsDeleteDialogOpen(true);
    const closeDeleteDialog = () => setIsDeleteDialogOpen(false);

    if (isLoading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;

    const { userClient = {}, identityDocument, reportToken, vehicleOwnershipRequests = [] } = user || {};

    return (
        <Box sx={{ p: 4 }}>
            <Stack direction="row" justifyContent="space-between" sx={{ mt: 4 }}>
                <Button variant="outlined" onClick={() => navigate(-1)}>
                    Back to User List
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={openDeleteDialog}
                >
                    Delete User
                </Button>
            </Stack>

            <Typography variant="h4" sx={{ mb: 4, textAlign: "center" }}>
                User Details
            </Typography>

            <Grid container spacing={4}>
                {/* User Basic Information */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">User Information</Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant="body1">
                                <strong>Name:</strong> {userClient.firstName || "Unknown"}{" "}
                                {userClient.lastName || "User"}
                            </Typography>
                            <Typography variant="body1">
                                <strong>Email:</strong> {userClient.emailAddress || "No Email Available"}
                            </Typography>
                            <Typography variant="body1">
                                <strong>Phone:</strong> {userClient.phoneNumber || "No Phone Number Available"}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Identity Document Information */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Identity Document</Typography>
                            <Divider sx={{ my: 2 }} />
                            {identityDocument ? (
                                <>
                                    <Typography variant="body1">
                                        <strong>Document ID:</strong> {identityDocument.id}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Added At:</strong> {identityDocument.addedAt}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Uploaded:</strong>{" "}
                                        {identityDocument.isUploaded ? "Yes" : "No"}
                                    </Typography>
                                </>
                            ) : (
                                <Typography variant="body1">No identity document available.</Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {/* Report Token Information */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Report Tokens</Typography>
                            <Divider sx={{ my: 2 }} />
                            {reportToken ? (
                                <>
                                    <Typography variant="body1" sx={{ mb: 2 }}>
                                        <strong>Remaining Tokens:</strong>{" "}
                                        {reportToken.remainingTokenCount}
                                    </Typography>
                                    <Typography variant="h6" sx={{ mb: 2 }}>
                                        Token Operation History:
                                    </Typography>
                                    <List>
                                        {reportToken.tokenOperationHistory.map((operation) => (
                                            <ListItem key={operation.id} disablePadding>
                                                <ListItemText
                                                    primary={`Operation: ${operation.operation}`}
                                                    secondary={`Remaining Tokens Before: ${operation.remainingTokenQuantityBefore}, Remaining Tokens After: ${operation.remainingTokenQuantityAfter}`}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </>
                            ) : (
                                <Typography variant="body1">
                                    No report token information available.
                                </Typography>
                            )}
                        </CardContent>
                        <CardActions>
                            <FormControl sx={{ minWidth: 120, mr: 2 }}>
                                <InputLabel id="increment-select-label">Quantity</InputLabel>
                                <Select
                                    labelId="increment-select-label"
                                    id="increment-select"
                                    value={incrementQuantity}
                                    onChange={(e) => setIncrementQuantity(e.target.value)}
                                    label="Quantity"
                                >
                                    {[...Array(10).keys()].map((val) => (
                                        <MenuItem key={val + 1} value={val + 1}>
                                            {val + 1}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Button
                                variant="contained"
                                onClick={handleIncrementTokens}
                                disabled={isUpdating}
                            >
                                {isUpdating ? "Updating..." : "Add Tokens"}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>

                {/* Vehicle Ownership Requests */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Vehicle Ownership Requests</Typography>
                            <Divider sx={{ my: 2 }} />
                            {vehicleOwnershipRequests.length > 0 ? (
                                <List>
                                    {vehicleOwnershipRequests.map((request, index) => (
                                        <ListItem key={index}>
                                            <ListItemText
                                                primary={`Request ID: ${request.id}`}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Typography variant="body1">
                                    No vehicle ownership requests available.
                                </Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={isDeleteDialogOpen}
                onClose={closeDeleteDialog}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Are you sure you want to delete this user? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteUser}
                        color="error"
                        disabled={isDeleting}
                    >
                        {isDeleting ? "Deleting..." : "Delete"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default UserDetailPage;

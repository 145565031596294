import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, Typography, FormControl, InputLabel, MenuItem, Select, Alert } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import PDFViewer from "../Global/Documents/PDFViewer";
import ImageViewer from "../Global/Documents/ImageViewer";
import { getDocument, putMarkDocumentAsValid, putMarkDocumentAsInvalid } from "../../api";

const DocumentDisplay = ({ documentId, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [document, setDocument] = useState(null);
    const [error, setError] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [rotationAngle, setRotationAngle] = useState(0);
    const [isRejected, setIsRejected] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await getDocument(documentId);
                console.log('Fetched document:', data); // Debug log for fetched document
                setDocument(data);
                setError(null); // Clear error if fetch is successful
            } catch (error) {
                console.error('Error fetching document:', error); // Debug log for errors
                setError(error.message || "An error occurred while fetching the document");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [documentId]);

    const handleRotate = () => setRotationAngle(prev => (prev + 90) % 360);
    const handleValidationChange = (event) => setIsValid(event.target.checked);
    const handleRejectionChange = (event) => {
        setIsRejected(event.target.checked);
        setIsValid(false);
    };
    const handleRejectionReasonChange = (event) => setRejectionReason(event.target.value);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (isValid) {
                await putMarkDocumentAsValid(document.id);
            } else if (isRejected && rejectionReason) {
                await putMarkDocumentAsInvalid(document.id, rejectionReason);
            }
        } catch (error) {
            console.error('Error submitting document:', error); // Debug log for errors
            setError(error.message || "An error occurred while submitting the document");
        } finally {
            setLoading(false);
            window.location.reload(); // Reload page after submission
        }
    };

    const renderContent = () => {
        if (loading) return <CircularProgress />;
        if (error) return <Alert severity="error">{error}</Alert>;
        if (!document) return <Alert severity="warning">Document data is unavailable. Please try again.</Alert>;

        const fileData = `data:${document.fileType};base64,${document.fileData}`;
        const uploadDate = new Date(document.uploadedAt).toLocaleDateString(); // Format the upload date

        return (
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ flex: 1, mr: 2 }}>
                    {document.fileType.includes("pdf") ? (
                        <PDFViewer fileData={fileData} />
                    ) : (
                        <ImageViewer imageData={fileData} rotationAngle={rotationAngle} onRotate={handleRotate} />
                    )}
                </Box>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>{document.user.firstName} {document.user.lastName}</Typography>
                    <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                        Uploaded At: {uploadDate}
                    </Typography>
                    <FormControlLabel
                        control={<Checkbox checked={isValid} onChange={handleValidationChange} disabled={isRejected} />}
                        label="This document is valid"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={isRejected} onChange={handleRejectionChange} disabled={isValid} />}
                        label="Reject this document"
                    />
                    {isRejected && (
                        <FormControl sx={{ mt: 2 }}>
                            <InputLabel id="rejection-reason-label">Reason</InputLabel>
                            <Select
                                labelId="rejection-reason-label"
                                id="rejection-reason"
                                value={rejectionReason}
                                label="Reason"
                                onChange={handleRejectionReasonChange}
                            >
                                <MenuItem value="INVALID_DOCUMENT">Invalid Document</MenuItem>
                                <MenuItem value="INVALID_DATA">Invalid Data</MenuItem>
                                <MenuItem value="EXPIRED">Expired</MenuItem>
                                <MenuItem value="BLURRED">Blurred</MenuItem>
                                <MenuItem value="OTHER">Other</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        sx={{ mt: 2 }}
                        disabled={(!isValid && (!isRejected || !rejectionReason))}
                    >
                        Submit
                    </Button>
                </Box>
            </Box>
        );
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'background.paper',
            boxShadow: 3,
            p: 2,
            overflowY: 'auto',
            position: 'relative'
        }}>
            <IconButton onClick={onClose} sx={{ position: 'absolute', right: 16, top: 16, color: 'gray' }}>
                <CloseIcon />
            </IconButton>
            {renderContent()}
        </Box>
    );
};

export default DocumentDisplay;

import { useState, useEffect } from 'react';  
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { login } from '../../../api';
import { authLogin } from '../../../redux/authActions';
import './LoginForm.css';

const LoginForm = ({ onLogin }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await login(email, password);

      if (response.status !== 200) {
        if (response.status === 401) {
          throw new Error(t('loginForm.incorrectCredentials'));  // Use translation
        } else {
          throw new Error(t('loginForm.loginFailed'));  // Use translation
        }
      }

      dispatch(authLogin(response.data.userUid, response.data.token));

      window.location.href = '/';
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage(t('loginForm.incorrectCredentials'));  // Use translation
      } else {
        setErrorMessage(t('loginForm.loginFailed'));  // Use translation
      }
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailAddress = queryParams.get('email');
    if (emailAddress) {
      setEmail(emailAddress);
    }
  }, [location.search]);
  
  return (
    <div className="login-form-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2 className="login-form__title">{t('loginForm.title')}</h2>
        <div className="login-form__group">
          <label htmlFor="email" className="login-form__label">{t('loginForm.emailLabel')}</label>
          <input type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="login-form__input" />
        </div>
        <div className="login-form__group">
          <label htmlFor="password" className="login-form__label">{t('loginForm.passwordLabel')}</label>
          <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} className="login-form__input" />
        </div>
        {errorMessage && (
          <div className="login-form__error">{errorMessage}</div>
        )}
        <button type="submit" className="login-form__button">{t('loginForm.loginButton')}</button>
      </form>
    </div>
  );
};

export default LoginForm;

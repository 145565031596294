import React, { useState, useEffect } from "react";
import { NavDropdown } from "react-bootstrap";
import useNotification from "../../../utils/useNotification";
import { updateNotificationStatus } from "../../../api";
import { useTranslation } from "react-i18next";
import NotificationItem from "./NotificationItem";
import "./NotificationDropdown.css";

const NotificationDropdown = () => {
  const { t } = useTranslation();
  // const notifications = useNotification();
  const notifications = [];
  const [newNotificationCount, setNewNotificationCount] = useState(0);

  useEffect(() => {
    const unreadNotificationsCount = notifications.filter(notification => !notification.read).length;
    setNewNotificationCount(unreadNotificationsCount);
  }, [notifications]);

  const handleDropdownClick = async () => {
    // Mark all notifications as read upon dropdown interaction.
    const unreadNotificationIds = notifications.filter(notification => !notification.read).map(notification => notification.id);
    if (unreadNotificationIds.length > 0) {
      try {
        await updateNotificationStatus(unreadNotificationIds);
      } catch (error) {
        console.error('Error updating notification status:', error);
      }
    }
  };

  return (
    <NavDropdown
      onClick={handleDropdownClick}
      title={
        <div>
          <img src="/notification.png" alt="Notifications" className="notification-bell-icon" width="30" height="30" />
          {newNotificationCount > 0 && (
            <span className="new-notification-count">{newNotificationCount}</span>
          )}
        </div>
      }
      id="notification-dropdown"
      menuVariant="dark"
      align="end"
      className="notification-container"
    >
      {/*{notifications.length > 0 ? (*/}
      {/*  notifications.map((notification, index) => (*/}
      {/*    <NotificationItem*/}
      {/*      key={notification.id || index}*/}
      {/*      notification={notification}*/}
      {/*      notificationRead={notification.read}*/}
      {/*    />*/}
      {/*  ))*/}
      {/*) : (*/}
      {/*  <NavDropdown.Item className="notification-item">*/}
      {/*    <div className="notification-item-container">*/}
      {/*      {t("notifications.none")}*/}
      {/*    </div>*/}
      {/*  </NavDropdown.Item>*/}
      {/*)}*/}
    </NavDropdown>
  );
};

export default NotificationDropdown;

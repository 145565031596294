import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

function useResponsiveSidebar() {
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992 && isLoggedIn) {
        setIsSidebarHidden(false);
      } else {
        setIsSidebarHidden(true);
      }
    };

    // Check if the sidebar is hidden on page load
    handleResize();

    // Set up an event listener to update the sidebar visibility when the window is resized
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isSidebarHidden;
}

export default useResponsiveSidebar;

import { combineReducers } from 'redux';

import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
} from './actionTypes';

const initialState = {
  auth: {
    isLoggedIn: null,
    userUid: null,
    jwt: null,
    timezone: null,
  },
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {      
    case AUTH_LOGIN:
      return {
        ...state,
        auth: {
          isLoggedIn: true,
          userUid: action.userUid,
          jwt: action.jwt,
          timezone: action.timezone
        },
      };

    case AUTH_LOGOUT:

      return {
        ...state,
        auth: {
          isLoggedIn: false,
          userUid: null,
          jwt: null,
          timezone: null
        },
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  auth: authReducer
});


export default rootReducer;

import React, { useState, useEffect } from "react";
import { getAllUsers } from "../../api.js";
import { TextField, List, ListItem, ListItemText, Typography, Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

const UserListPage = () => {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const data = await getAllUsers();
                setUsers(data);
                setFilteredUsers(data);
            } catch (err) {
                setError(err.message || "An error occurred while fetching users");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        // Filter users based on the search term
        const results = users.filter((user) => {
            const fullName = `${user.firstName || ""} ${user.lastName || ""}`.toLowerCase();
            const email = user.emailAddress?.toLowerCase() || "";
            const search = searchTerm.toLowerCase();

            return fullName.includes(search) || email.includes(search);
        });

        setFilteredUsers(results);
    }, [searchTerm, users]);

    if (isLoading) return <CircularProgress />;
    if (error) return <Typography color="error">Error: {error}</Typography>;

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h4" sx={{ mb: 2 }}>
                User List
            </Typography>
            <TextField
                label="Search Users"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ mb: 2 }}
            />
            <List>
                {filteredUsers.map((user) => {
                    // Format creation date and time
                    const formattedDate = user.createdAt
                        ? new Date(user.createdAt).toLocaleString()
                        : "Unknown Creation Date";

                    const fullName = `${user.firstName || ""} ${user.lastName || ""}`.trim();

                    return (
                        <ListItem
                            key={user.id}
                            button
                            onClick={() => navigate(`/users/${user.id}`)}
                        >
                            <ListItemText
                                primary={`${user.emailAddress || "No Email"}`}
                                secondary={
                                    <>
                                        {fullName && <span>{fullName}</span>}
                                        {fullName && <br />}
                                        {`Signed Up: ${formattedDate}`}
                                    </>
                                }
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};

export default UserListPage;

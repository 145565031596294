export const generateDateRange = (start, end) => {
  const dates = [];
  let currentDate = new Date(start);
  const endDate = new Date(end);

  while (currentDate <= endDate) {
    dates.push(currentDate.toISOString().split("T")[0]);
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }

  return dates;
};

import React, { useState, useEffect } from "react";
import { getUncheckedVehicleOwnershipDocuments } from "../../api";
import TotalCountDisplay from "../Global/TotalCountDisplay";
import VehicleDocumentDisplay from "./VehicleDocumentDisplay";
import { List, ListItem, ListItemText, Typography, Grid, Box } from "@mui/material";

const VehicleOwnershipDocumentsPage = () => {
    const [uncheckedDocumentCount, setUncheckedDocumentCount] = useState(0);
    const [uncheckedDocuments, setUncheckedDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const data = await getUncheckedVehicleOwnershipDocuments();

                if (Array.isArray(data)) {
                    setUncheckedDocuments(data);
                    setUncheckedDocumentCount(data.length);
                    setSelectedDocument(data.length > 0 ? data[0] : null);
                } else {
                    // If response is not an array, handle gracefully
                    console.error("Unexpected response format:", data);
                    setUncheckedDocuments([]);
                    setUncheckedDocumentCount(0);
                    setSelectedDocument(null);
                }
            } catch (error) {
                console.error("Error fetching vehicle ownership documents:", error);
                setError(error.message || "An error occurred while fetching vehicle ownership documents");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);


    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading data: {error}</div>;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <TotalCountDisplay label="Total Vehicle Ownership Documents" count={uncheckedDocumentCount} />
                {uncheckedDocumentCount === 0 ? (
                    <Box sx={{ p: 2, textAlign: "center" }}>
                        <Typography variant="body1" color="textSecondary">
                            There are no vehicle ownership documents to review.
                        </Typography>
                    </Box>
                ) : (
                    <List sx={{ width: "100%", bgcolor: "background.paper", maxHeight: "90vh", overflow: "auto" }}>
                        {uncheckedDocuments.map((doc) => (
                            <ListItem
                                key={doc.id}
                                divider
                                button
                                onClick={() => setSelectedDocument(doc)}
                            >
                                <ListItemText
                                    primary={
                                        doc.ownerClient ? (
                                            <Typography variant="subtitle1">
                                                {doc.ownerClient.firstName} {doc.ownerClient.lastName}
                                            </Typography>
                                        ) : (
                                            <Typography variant="subtitle1" color="textSecondary">
                                                Unknown Owner
                                            </Typography>
                                        )
                                    }
                                    secondary={
                                        <Typography variant="body2" color="textSecondary">
                                            Click to view vehicle document
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
            </Grid>
            <Grid item xs={12} md={9}>
                {selectedDocument ? (
                    <VehicleDocumentDisplay documentId={selectedDocument.id} />
                ) : uncheckedDocumentCount > 0 ? (
                    <Box sx={{ p: 2, textAlign: "center" }}>
                        <Typography variant="body1" color="textSecondary">
                            Select a document from the list to view details.
                        </Typography>
                    </Box>
                ) : null}
            </Grid>
        </Grid>
    );
};

export default VehicleOwnershipDocumentsPage;

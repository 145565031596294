import React, { useState, useEffect } from "react";
import MetricGraph from "./Components/MetricGraph";
import TotalCountDisplay from "../../Global/TotalCountDisplay";
import CustomerFunnel from "./Components/CustomerFunnel";
import { getUserCounts, getVehicleCount, getInvoiceCount } from "../../../api";
import { generateDateRange } from "../../../utils/generateDateRange";
import { mergeData } from "../../../utils/mergeData";
import { Grid } from '@mui/material';

const MetricsPage = () => {
    const [totalVehicleCount, setTotalVehicleCount] = useState(0);
    const [dailyVehicleCounts, setDailyVehicleCounts] = useState([]);
    const [totalCustomerCount, setTotalCustomerCount] = useState(0);
    const [totalOnboardedUserCount, setTotalOnboardedUserCount] = useState(0);
    const [dailyCustomerCounts, setDailyCustomerCounts] = useState([]);
    const [totalInvoiceCount, setTotalInvoiceCount] = useState(0);
    const [dailyInvoiceCounts, setDailyInvoiceCounts] = useState([]);

    // State for users in different steps
    const [usersAwaitingEmailConfirmation, setUsersAwaitingEmailConfirmation] = useState(0);
    const [usersAwaitingPersonalInformation, setUsersAwaitingPersonalInformation] = useState(0);
    const [usersAwaitingToSubmitId, setUsersAwaitingToSubmitId] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const vehicleData = await getVehicleCount();
                setTotalVehicleCount(vehicleData.totalCount);
                setDailyVehicleCounts(
                    vehicleData.dailyCount.map((dc) => ({
                        ...dc,
                        vehicleCount: parseInt(dc.vehicleCount, 10),
                    }))
                );

                const customerData = await getUserCounts();

                setTotalCustomerCount(customerData.totalCount);
                setTotalOnboardedUserCount(customerData.totalOnboardedUserCount);
                setUsersAwaitingEmailConfirmation(customerData.usersAwaitingEmailConfirmation);
                setUsersAwaitingPersonalInformation(customerData.usersAwaitingPersonalInformation);
                setUsersAwaitingToSubmitId(customerData.usersAwaitingToSubmitId);
                setDailyCustomerCounts(
                    customerData.dailyCount.map((dc) => ({
                        ...dc,
                        customerCount: parseInt(dc.customerCount, 10),
                    }))
                );

                const invoiceData = await getInvoiceCount();
                setTotalInvoiceCount(invoiceData.totalCount);
                setDailyInvoiceCounts(
                    invoiceData.dailyCount.map((dc) => ({
                        ...dc,
                        invoiceCount: parseInt(dc.invoiceCount, 10),
                    }))
                );
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const currentDate = new Date();
    const startDate = new Date().setDate(currentDate.getDate() - 7);
    const dateRange = generateDateRange(startDate, currentDate);

    const enhancedDailyVehicleCounts = dateRange.map((date) => {
        const existingEntry = dailyVehicleCounts.find(
            (entry) => entry.registeredAt === date
        );
        return {
            registeredAt: date,
            vehicleCount: existingEntry ? existingEntry.vehicleCount : 0,
        };
    });

    const enhancedDailyCustomerCounts = dateRange.map((date) => {
        const existingEntry = dailyCustomerCounts.find(
            (entry) => entry.registeredAt === date
        );
        return {
            registeredAt: date,
            customerCount: existingEntry ? existingEntry.customerCount : 0,
        };
    });

    const enhancedDailyInvoiceCounts = dateRange.map((date) => {
        const existingEntry = dailyInvoiceCounts.find(
            (entry) => entry.registeredAt === date
        );
        return {
            registeredAt: date,
            invoiceCount: existingEntry ? existingEntry.invoiceCount : 0,
        };
    });

    const mergedCounts = mergeData(
        enhancedDailyVehicleCounts,
        enhancedDailyCustomerCounts,
        enhancedDailyInvoiceCounts
    );

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading data: {error.message}</div>;

    return (
        <div>
            <Grid
                container
                spacing={2}
                style={{
                    width: "100%",
                    margin: "0 auto",
                }}
            >
                <Grid item xs={12} sm={6} md={3}>
                    <TotalCountDisplay
                        label="Onboarded Users"
                        count={totalOnboardedUserCount}
                        iconType="onboardedUsers"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TotalCountDisplay
                        label="Users"
                        count={totalCustomerCount}
                        iconType="customers"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TotalCountDisplay
                        label="Vehicles"
                        count={totalVehicleCount}
                        iconType="vehicles"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TotalCountDisplay
                        label="Invoices"
                        count={totalInvoiceCount}
                        iconType="invoices"
                    />
                </Grid>
            </Grid>

            <CustomerFunnel
                totalUserCount={totalCustomerCount}
                usersAwaitingEmailConfirmation={usersAwaitingEmailConfirmation}
                usersAwaitingPersonalInformation={usersAwaitingPersonalInformation}
                usersAwaitingToSubmitId={usersAwaitingToSubmitId}
            />

            <MetricGraph
                data={mergedCounts}
                metrics={[
                    { dataKey: "vehicleCount", fill: "#82ca9d" },
                    { dataKey: "customerCount", fill: "#8884d8" },
                    { dataKey: "invoiceCount", fill: "#A50104" },
                ]}
            />
        </div>
    );
};

export default MetricsPage;

import React, { useEffect, useState } from 'react';
import { CircularProgress, Button, Box } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ fileData, onLoadSuccess }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [currentPage, setCurrentPage] = useState(1);
    const [numPages, setNumPages] = useState(null); // Manage numPages locally

    const goToPrevPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
    const goToNextPage = () => setCurrentPage(prev => Math.min(prev + 1, numPages));

    // Default handler if onLoadSuccess is not provided
    const handleLoadSuccess = ({ numPages: loadedPages }) => {
        setNumPages(loadedPages); // Update local numPages state
        setCurrentPage(1); // Reset to first page on load
        if (onLoadSuccess) {
            onLoadSuccess(loadedPages); // Call parent-provided handler if available
        }
    };

    return (
        <>
            <Document
                file={fileData}
                onLoadSuccess={handleLoadSuccess}
                loading={<CircularProgress />}
            >
                <Page
                    pageNumber={currentPage}
                    scale={isSmallScreen ? 0.6 : 0.81}
                />
            </Document>
            {numPages > 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button onClick={goToPrevPage} disabled={currentPage === 1}>
                        Previous
                    </Button>
                    <Button onClick={goToNextPage} disabled={currentPage === numPages}>
                        Next
                    </Button>
                </Box>
            )}
        </>
    );
};

export default PDFViewer;

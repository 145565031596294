import React from 'react';
import { Card, Typography } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';


const TotalCountDisplay = ({ count, label, iconType }) => {
    let Icon;

    switch (iconType) {
        case 'customers':
            Icon = PeopleIcon;
            break;
        case 'vehicles':
            Icon = DirectionsCarIcon;
            break;
        case 'invoices':
            Icon = ReceiptLongIcon;
            break;
        case 'onboardedUsers':
            Icon = AssignmentIndIcon;
            break;
        default:
            Icon = PeopleIcon;
    }
    return (
        <Card
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 2,
                marginBottom: 2,
                boxShadow: 3,
                width: '250px', // Fixed width
                height: '150px', // Fixed height
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon style={{ marginRight: 8, color: iconType === 'customers' ? '#1976d2' : '#2e7d32' }} />
                <Typography variant="h6" component="h2">
                    {label}
                </Typography>
            </div>
            <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                {count}
            </Typography>
        </Card>
    );
};

export default TotalCountDisplay;

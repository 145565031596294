import React, {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import {useSelector} from "react-redux";
import TopBar from "../src/Components/Global/TopBar";
import SideBar from "../src/Components/Global/SideBar";
import useResponsiveSidebar from "./utils/isSidebarHidden";
import routes from "./routes";
import "./App.css";

function App() {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const appRoutes = routes(isLoggedIn);
    const isSidebarHidden = useResponsiveSidebar();

    useEffect(() => {
        function checkWindowSize() {
            if (isLoggedIn) {
                const sidebar = document.querySelector(".sidebar");
                if (window.innerWidth <= 992) {
                    sidebar.classList.add("hide");
                } else {
                    sidebar.classList.remove("hide");
                }
            }
        }

        // Execute checkWindowSize immediately
        checkWindowSize();

        window.addEventListener("load", checkWindowSize);
        window.addEventListener("resize", checkWindowSize);

        // Cleanup function to remove the event listeners
        return () => {
            window.removeEventListener("load", checkWindowSize);
            window.removeEventListener("resize", checkWindowSize);
        };
    }, [isLoggedIn]);

    return (
        <>
            <TopBar isLoggedIn={isLoggedIn}/>
            {isLoggedIn && <SideBar className="sidebar"/>}

            <div
                className={
                    isSidebarHidden
                        ? "container-global"
                        : "sidebar-margin container-global"
                }
            >
                <div className={"layout-main"}>
                    <Routes>
                        {appRoutes.flatMap((routeGroup) =>
                            routeGroup.children.map((childRoute, childIndex) => (
                                <Route
                                    key={childIndex}
                                    path={childRoute.path}
                                    element={childRoute.element}
                                />
                            ))
                        )}
                    </Routes>
                </div>
            </div>
        </>
    );
}

export default App;

import React from "react";
import LoginForm from "./Components/AccessControl/Login/LoginForm";
import LogoutForm from "./Components/AccessControl/Logout/LogoutForm";
import NotFound from "./Components/Global/NotFound";
import {Navigate} from "react-router-dom";
import MetricsPage from "./Components/Metrics/MainMetrics/MetricsPage";
import ApiCallsPricingStats from "./Components/Metrics/ExternalApiCalls/ApiCallsPricingStats";
import IdentityDocumentsPage from "./Components/Documents/IdentityDocumentsPage";
import VehicleOwnershipDocumentsPage from "./Components/Documents/VehicleOwnershipDocumentPage";
import UserListPage from "./Components/Users/UserListPage";
import UserDetailPage from "./Components/Users/UserDetailsPage";

const protectedRoute = (isLoggedIn, Component) => {
    return isLoggedIn ? Component : <Navigate to="/login"/>;
};

const routes = (isLoggedIn) => [
    {
        path: "/",
        children: [
            // Metrics
            {
                path: "/",
                element: protectedRoute(isLoggedIn, <MetricsPage/>)
            },
            {
                path: '/api-calls-metrics',
                element: protectedRoute(isLoggedIn, <ApiCallsPricingStats/>)
            },

            // Auth
            {
                path: "/logout",
                element: isLoggedIn ? <LogoutForm/> : <Navigate to="/login"/>,
            },
            {
                path: "/login",
                element: !isLoggedIn ? <LoginForm/> : <Navigate to="/"/>,
            },

            // Documents
            {
                path: "/identity-documents",
                element: protectedRoute(isLoggedIn, <IdentityDocumentsPage/>),
            },
            {
                path: "/vehicle-ownership-documents",
                element: protectedRoute(isLoggedIn, <VehicleOwnershipDocumentsPage/>),
            },

            // Users
            {
                path: "/users",
                element: protectedRoute(isLoggedIn, <UserListPage/>),
            },
            {
                path: "/users/:userId",
                element: protectedRoute(isLoggedIn, <UserDetailPage/>),
            },
            // Default
            {path: "*", element: <NotFound/>},
        ],
    },
];

export default routes;

import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const MetricGraph = ({ data, metrics }) => {
    console.log(data);
  return (
    <div>
      <h3>Counts Over Time</h3>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="registeredAt" />
          <YAxis />
          <Tooltip />
          <Legend />
          {metrics.map(metric => (
            <Bar key={metric.dataKey} dataKey={metric.dataKey} fill={metric.fill} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MetricGraph;

import React, { useRef } from 'react';
import { Box, IconButton } from '@mui/material';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ImageViewer = ({ imageData, rotationAngle, onRotate }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const imageRef = useRef(null);

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            imageRef.current.requestFullscreen().catch(err => {
                alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };

    return (
        <Box sx={{ textAlign: 'center' }}>
            <img
                ref={imageRef}
                src={imageData}
                alt="Displayed Image"
                style={{
                    width: isSmallScreen ? "100vw" : "30vw",
                    maxHeight: "90vh",
                    transform: `rotate(${rotationAngle}deg)`,
                    transition: "transform 0.3s ease",
                    cursor: 'pointer'
                }}
                onClick={toggleFullScreen}
                onContextMenu={(e) => e.preventDefault()}  // Prevent right-click
            />
            <Box mt={2}>
                <IconButton onClick={onRotate} aria-label="rotate">
                    <RotateRightIcon />
                </IconButton>
                <IconButton onClick={toggleFullScreen} aria-label="toggle full screen">
                    {document.fullscreenElement ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
            </Box>
        </Box>
    );
};

export default ImageViewer;

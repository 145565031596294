export const mergeData = (vehicleCounts, customerCounts, invoiceCounts) => {
  const allDates = new Set(
    [...vehicleCounts, ...customerCounts, ...invoiceCounts].map(
      (data) => data.registeredAt
    )
  );
  const mergedData = [];

  allDates.forEach((date) => {
    const vehicleData = vehicleCounts.find((v) => v.registeredAt === date) || {
      vehicleCount: 0,
    };
    const customerData = customerCounts.find(
      (c) => c.registeredAt === date
    ) || { customerCount: 0 };
    const invoiceData = invoiceCounts.find((i) => i.registeredAt === date) || {
      invoiceCount: 0,
    };

    mergedData.push({
      registeredAt: date,
      vehicleCount: vehicleData.vehicleCount,
      customerCount: customerData.customerCount,
      invoiceCount: invoiceData.invoiceCount,
    });
  });

  return mergedData.sort(
    (a, b) => new Date(a.registeredAt) - new Date(b.registeredAt)
  );
};

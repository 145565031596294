import React, { useState, useEffect } from "react";
import { getUncheckedDocuments } from "../../api";
import TotalCountDisplay from "../Global/TotalCountDisplay";
import DocumentDisplay from "./DocumentDisplay";
import { List, ListItem, ListItemText, Typography, Grid } from "@mui/material";

const IdentityDocumentsPage = () => {
    const [uncheckedDocumentCount, setUncheckedDocumentCount] = useState(0);
    const [uncheckedDocuments, setUncheckedDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const data = await getUncheckedDocuments();
                if (data.count === 0) {
                    setUncheckedDocuments([]);
                    setUncheckedDocumentCount(0);
                }
                else if (Array.isArray(data.documents) && data.documents.length > 0) {
                    setUncheckedDocuments(data.documents);
                    setUncheckedDocumentCount(data.count);
                    setSelectedDocument(data.documents[0]); // Set the first document as selected by default
                } else {
                    throw new Error("Error!!");
                }
            } catch (error) {
                setError(error.message || "An error occurred while fetching documents");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading data: {error}</div>;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <TotalCountDisplay label="Total Unchecked Documents" count={uncheckedDocumentCount} />
                <List sx={{ width: '100%', bgcolor: 'background.paper', maxHeight: '90vh', overflow: 'auto' }}>
                    {uncheckedDocuments.map(doc => (
                        <ListItem key={doc.id} divider button onClick={() => setSelectedDocument(doc)}>
                            <ListItemText
                                primary={<Typography variant="subtitle1">{doc.userClient.firstName} {doc.userClient.lastName}</Typography>}
                                secondary={<Typography variant="body2" color="textSecondary">Click to view details</Typography>}
                            />
                        </ListItem>
                    ))}
                </List>
            </Grid>
            <Grid item xs={12} md={9}>
                {selectedDocument && (
                    <DocumentDisplay documentId={selectedDocument.id} />
                )}
            </Grid>
        </Grid>
    );
};

export default IdentityDocumentsPage;

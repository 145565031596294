import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
  faFileInvoice,
  faCarAlt,
  faSignOutAlt,
  faFileClipboard
} from '@fortawesome/free-solid-svg-icons';
import './MobileMenuOverlay.css';


function MobileMenuOverlay({ onClose, isOpen }) {
  const { t } = useTranslation();
  const toggleButtonRef = useRef(null);
  const overlayRef = useRef(null);

  const menuItems = [
    {
      label: t('menu.metrics'),
      icon: faCarAlt,
      path: '/',
    },
    {
      label: t('menu.apiMetrics'),
      icon: faFileInvoice,
      path: '/api-calls-metrics',
    },
    {
      label: t('menu.identityDocuments'),
      icon: faFileClipboard,
      path: '/identity-documents',
    },
    {
      label: t('menu.userList'),
      icon: faFileClipboard,
      path: '/users',
    },
    {
      label: t('menu.vehicleOwnershipDocuments'),
      icon: faFileClipboard,
      path: '/vehicle-ownership-documents',
    },
    {
      label: t('menu.logout'),
      icon: faSignOutAlt,
      path: '/logout',
    },
  ];

  useEffect(() => {
    if (isOpen) {
      toggleButtonRef.current.classList.add('toggle-active');
      overlayRef.current.classList.add('nav-active');
    } else {
      toggleButtonRef.current.classList.remove('toggle-active');
      overlayRef.current.classList.remove('nav-active');
    }
  }, [isOpen]);

  return (
    <header className="container">
      <div className="navbar-header">
        <div
          className="toggle-button"
          id="toggle"
          ref={toggleButtonRef}
          onClick={onClose}
        >
        </div>
      </div>
      <nav className="overlay" id="overlay" ref={overlayRef}>
        <ul>
          {menuItems.map((item) => (
            <li key={item.label}>
              <Link
                to={item.path}
                onClick={onClose}
                className={item.label === t('menu.logout') ? 'logout-button' : ''}
              >
                <div className={item.label === t('menu.logout') ? 'menu-item-icon-logout' : 'menu-item-icon'}>
                  <FontAwesomeIcon icon={item.icon} />
                </div>
                <span className={item.label === t('menu.logout') ? 'menu-item-text-logout' : 'menu-item-text'}>
                  {item.label}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}

export default MobileMenuOverlay;

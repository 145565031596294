import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faArrowTrendUp,
    faFileClipboard,
    faFileInvoice,
    faSortNumericDesc,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import {List, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import './SideBar.css';
import {useTranslation} from "react-i18next";

const SideBar = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const menuItems = [
        {
            label: t('menu.metrics'),
            icon: faSortNumericDesc,
            path: '/',
        },
        {
            label: t('menu.apiMetrics'),
            icon: faArrowTrendUp,
            path: '/api-calls-metrics',
        },
        {
            label: t('menu.identityDocuments'),
            icon: faFileClipboard,
            path: '/identity-documents',
        },
        {
            label: t('menu.users'),
            icon: faUsers,
            path: '/users',
        },
        {
            label: t('menu.vehicleOwnershipDocuments'),
            icon: faFileClipboard,
            path: '/vehicle-ownership-documents',
        },
    ];

    return (
        <div className="sidebar">
            <List component="nav">
                {menuItems.map((item) => (
                    <ListItem
                        button
                        key={item.label}
                        component={Link}
                        to={item.path}
                        className={`sidebar-link${item.path === location.pathname ? ' active' : ''}`}
                    >
                        <ListItemIcon className="sidebar-icon">
                            <FontAwesomeIcon icon={item.icon}/>
                        </ListItemIcon>
                        <ListItemText primary={item.label}/>
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default SideBar;

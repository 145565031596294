import React, { useEffect, useState } from 'react';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ImageViewer from "../Global/Documents/ImageViewer";
import { getVehicleOwnershipDocument, markVehicleDocumentAsInvalid, markVehicleDocumentAsValid } from "../../api";
import PDFViewer from "../Global/Documents/PDFViewer";

const VehicleDocumentDisplay = ({ documentId, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [document, setDocument] = useState(null);
    const [error, setError] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [rotationAngle, setRotationAngle] = useState(0);
    const [isRejected, setIsRejected] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // For modal loading state
    const [submissionError, setSubmissionError] = useState(null); // To display error in modal

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await getVehicleOwnershipDocument(documentId);
                setDocument(data);
                setError(null); // Clear error if fetch is successful
            } catch (error) {
                setError(error.message || "An error occurred while fetching the document");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [documentId]);

    const handleRotate = () => setRotationAngle((prev) => (prev + 90) % 360);
    const handleValidationChange = (event) => setIsValid(event.target.checked);
    const handleRejectionChange = (event) => {
        setIsRejected(event.target.checked);
        setIsValid(false);
    };

    const handleRejectionReasonChange = (event) => setRejectionReason(event.target.value);

    const handleSubmit = async () => {
        setIsSubmitting(true); // Show modal with loader
        setSubmissionError(null); // Clear previous errors
        try {
            if (isValid) {
                await markVehicleDocumentAsValid(document.id);
            } else if (isRejected && rejectionReason) {
                await markVehicleDocumentAsInvalid(document.id, rejectionReason);
            }
            setSubmissionError(null); // Clear error on success
            window.location.reload(); // Reload after successful submission
        } catch (error) {
            setSubmissionError(error.message || "An error occurred while submitting the document");
        } finally {
            setIsSubmitting(false); // Stop loading
        }
    };

    const renderFiles = () => {
        if (!document.files || document.files.length === 0) {
            return (
                <Typography variant="body1" color="textSecondary">
                    No files available for this document.
                </Typography>
            );
        }

        return document.files.map((file, index) => {
            const fileData = `data:${file.fileType};base64,${file.fileData}`;

            return (
                <Box key={index} sx={{ mb: 2 }}>
                    {file.fileType.includes("pdf") ? (
                        <PDFViewer fileData={fileData} />
                    ) : (
                        <ImageViewer imageData={fileData} rotationAngle={rotationAngle} onRotate={handleRotate} />
                    )}
                </Box>
            );
        });
    };

    const renderContent = () => {
        if (loading) return <CircularProgress />;
        if (error) return <Alert severity="error">{error}</Alert>;
        if (!document) return <Alert severity="info">No document data available</Alert>;

        const requestDate = document.requestOpeningDate
            ? new Date(document.requestOpeningDate).toLocaleDateString()
            : "Unknown request date";

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                {/* Owner and Vehicle Details */}
                <Box
                    component={Paper}
                    elevation={3}
                    sx={{
                        p: 2,
                        mb: 3,
                        borderRadius: 2,
                        backgroundColor: 'background.default',
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 1 }}>
                        Owner Information
                    </Typography>
                    <Typography variant="body2">
                        <strong>ID:</strong> {document.ownerClient.id}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Name:</strong> {document.ownerClient.firstName} {document.ownerClient.lastName}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Email:</strong> {document.ownerClient.emailAddress}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Phone:</strong> {document.ownerClient.phoneNumber}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Created At:</strong> {new Date(document.ownerClient.createdAt).toLocaleDateString()}
                    </Typography>
                </Box>

                <Box
                    component={Paper}
                    elevation={3}
                    sx={{
                        p: 2,
                        mb: 3,
                        borderRadius: 2,
                        backgroundColor: 'background.default',
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 1 }}>
                        Vehicle Information
                    </Typography>
                    <Typography variant="body2">
                        <strong>ID:</strong> {document.vehicle.id}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Category:</strong> {document.vehicle.category}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Color:</strong> {document.vehicle.colour}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Year:</strong> {document.vehicle.yearOfManufacture}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Make:</strong> {document.vehicle.make}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Model:</strong> {document.vehicle.makeModel}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Transmission:</strong> {document.vehicle.transmissionType}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Fuel Type:</strong> {document.vehicle.fuelType}
                    </Typography>
                </Box>

                {/* Request Details */}
                <Box sx={{ mb: 3 }}>
                    <Typography variant="body2">
                        <strong>Request Date:</strong> {requestDate}
                    </Typography>
                </Box>

                {/* Highlight Plate Number */}
                <Box sx={{ textAlign: 'center', mb: 3 }}>
                    <Typography
                        variant="h3"
                        sx={{
                            color: 'primary.main',
                            fontWeight: 'bold',
                            mb: 1,
                        }}
                    >
                        {document.vehicle.vehicleRegistrationPlate}
                    </Typography>
                </Box>

                {/* Files */}
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    Attached Files
                </Typography>
                {renderFiles()}

                {/* Validation and Submission */}
                <FormControlLabel
                    control={<Checkbox checked={isValid} onChange={handleValidationChange} disabled={isRejected} />}
                    label="This vehicle document is valid"
                />
                <FormControlLabel
                    control={<Checkbox checked={isRejected} onChange={handleRejectionChange} disabled={isValid} />}
                    label="Reject this vehicle document"
                />
                {isRejected && (
                    <FormControl sx={{ mt: 2 }}>
                        <InputLabel id="rejection-reason-label">Reason</InputLabel>
                        <Select
                            labelId="rejection-reason-label"
                            id="rejection-reason"
                            value={rejectionReason}
                            label="Reason"
                            onChange={handleRejectionReasonChange}
                        >
                            <MenuItem value="INVALID_DOCUMENT">Invalid Document</MenuItem>
                            <MenuItem value="INVALID_DATA">Invalid Data</MenuItem>
                            <MenuItem value="EXPIRED">Expired</MenuItem>
                            <MenuItem value="BLURRED">Blurred</MenuItem>
                            <MenuItem value="OTHER">Other</MenuItem>
                        </Select>
                    </FormControl>
                )}
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{ mt: 2 }}
                    disabled={(!isValid && (!isRejected || !rejectionReason))}
                >
                    Submit
                </Button>
            </Box>
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'background.paper',
                boxShadow: 3,
                p: 2,
                overflowY: 'auto',
                position: 'relative',
            }}
        >
            <IconButton onClick={onClose} sx={{ position: 'absolute', right: 16, top: 16, color: 'gray' }}>
                <CloseIcon />
            </IconButton>
            {renderContent()}

            {/* Submission Modal */}
            <Modal
                open={isSubmitting || !!submissionError}
                onClose={() => {}}
                aria-labelledby="submission-modal-title"
                aria-describedby="submission-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        textAlign: 'center',
                    }}
                >
                    {isSubmitting && <CircularProgress />}
                    {submissionError && <Alert severity="error">{submissionError}</Alert>}
                </Box>
            </Modal>
        </Box>
    );
};

export default VehicleDocumentDisplay;

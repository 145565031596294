import {
  AUTH_LOGIN,
  AUTH_LOGOUT
} from './actionTypes';

export const authLogin = (userUid, jwt) => async (dispatch) => {
  dispatch({
    type: AUTH_LOGIN,
    userUid,
    jwt
  })
};

export const authLogout = () => async (dispatch) => {
  dispatch({
    type: AUTH_LOGOUT,
  })
};
